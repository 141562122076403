<template>
  <page-layout>
    <!-- 充值记录 -->
    <div class="ma-24 d-flex align-end justify-space-between">
      <div></div>
      <a-button @click="exportVoucher" class="d-flex align-center">
        <Icon name="icondaochu1" width="15" height="15"></Icon>
        <span class="ml-8">导出</span>
      </a-button>
    </div>
    <search-header
      :schemaData="searchFields"
      @getDataList="handleFormSearch"
    ></search-header>
    <!-- 列表 -->
    <div class="mx-24 mt-12 bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        :columns="tableColumns"
        dataKey="modal"
      >
        <template #footer>
          <span style="color:#808080" class="w400 ml-12"
            >累计充值: {{ totalAmount }}元</span
          >
        </template>
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, toRaw, ref } from "vue";
import { message } from "ant-design-vue";
import { getInitTable } from "@/hooks/form-hook";
import { preview } from "@/helper/preview-helper";
import { useSearchField } from "./constants/index";
import { Table } from "@wlhy-web-components/common";
import { getColumns } from "./constants/table-config";
import useSearchForm from "@/hooks/UseSearchForm";
import {
  useRechargeExportApi,
  useDailyRechargePageApi
} from "@/apis/financial";

export default defineComponent({
  name: "prepaid-records",

  setup() {
    const totalAmount = ref(0);
    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());
    const { searchFields } = useSearchField();

    const { filter, handleFormSearch } = useSearchForm(searchFields);

    const rechargeExport = useRechargeExportApi();
    const dailyRechargePage = useDailyRechargePageApi();

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await dailyRechargePage(data);
        totalAmount.value = +res.ext.amount / 100;
        return { records: res.list, total: res.total };
      }
    });

    tableColumns.forEach(item => {
      switch (item.dataIndex) {
        // 审核状态
        case "action":
          item.customRender = (_, row) => {
            return (
              <div>
                <a
                  class="color-primary  text-no-wrap"
                  style={
                    row.oss === null
                      ? "color:#b1b1b1 !important;"
                      : "color: #1684FB !important;"
                  }
                  onClick={() => handleActionClick(row)}
                >
                  凭证
                </a>
              </div>
            );
          };
          break;
      }
    });
    // 点击凭证，查看图片
    const handleActionClick = async row => {
      if (row.oss === null) return;
      preview(row && row.oss);
    };

    // 导出数据
    const exportVoucher = async () => {
      // TODO  导出数据
      const data = {
        ...toRaw(filter.value),
        export_flag: true,
        size: tableDataSource.meta.pageSize,
        page: tableDataSource.meta.currentPageNo
      };

      const res = await rechargeExport(data);
      if (res) {
        message.success("导出成功，请前往下载中心查看");
      }
    };

    return {
      preview,
      totalAmount,
      searchFields,
      tableColumns,
      exportVoucher,
      handleFormSearch,
      getConsultingList,
      handleActionClick,
      dataSource: tableDataSource
    };
  }
});
</script>
