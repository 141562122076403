/**
 * 搜索头
 */
// 表单配置
// 表单和运营模式无关 只和状态相关
// excludes表示不显示的状态
export const useSearchField = () => {
  const searchFields = [
    {
      field: "card_no",
      label: "账号",
      type: "input",
      iw: 200
    },
    {
      field: ["start_time", "end_time"],
      iw: 320,
      label: "时间",
      type: "dateRange",
      props: {
        placeholder: "请选择时间",
        dateFormat: "YYYY/MM/DD"
      }
    }
  ];

  return { searchFields };
};
