//table列配置
export const getColumns = () => {
  const columns = [
    {
      width: 100,
      type: "checkbox",
      field: "$$id",
      fixed: "left",
      align: "left",
      title: "序号",
      hidden: true
    },
    { width: 80, type: "seq", title: "序号" },
    {
      title: "账号",
      dataIndex: "card_no",
      minWidth: 120
    },
    {
      title: "账户类型",
      dataIndex: "account_type_desc",
      minWidth: 120
    },
    {
      title: "充值金额(元)",
      field: "recharge_amount",
      minWidth: 120,
      cellRender: "p-money"
    },
    {
      title: "时间",
      field: "valid_start",
      minWidth: 100,
      cellRender: "p-date"
    },
    {
      title: "操作",
      dataIndex: "action",
      width: 80,
      align: "let",
      fixed: "right"
    }
  ];
  return columns;
};
